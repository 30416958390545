type FlowFormat = `[${Flow}:${string}]` // [flow:function/api call naming] e.g. [simulation:createProspect]
type CmsFormat = `[CMS:${string}:${string}]` // [CMS:page:function] e.g. [CMS:career:getStaticProps]

export type IdentifierFormats = FlowFormat | CmsFormat

export type Logging<T extends IdentifierFormats> = {
  severity?: Severity // default is error
  identifier: T
  error: Error | string
  metaData?: {
    [section: string]: {
      [key: string]: unknown
    }
  }
  criticality?: Criticality
}

export enum Criticality {
  CRITICAL = 'critical',
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low'
}

export enum Flow {
  SIMULATION = 'simulation',
  REGISTRATION = 'registration',
  COMMON = 'common',
  ENERGY_GARDENS = 'energyGardens'
}

export enum Severity {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info'
}
