import { ElectricityAndGasPrices } from 'features/simulation/types'
import { InputGroups } from 'store/customer/types'
import { MarketingData } from './marketing-params'
import { ELProduct } from './product-data'
import { FuelPricelistUrls } from 'types/customer'

export type GetDataByOpportunityIdResponse = {
  prospect: {
    forcedAmount: number
    boltGoPrices?: ElectricityAndGasPrices
    fixedPrices?: ElectricityAndGasPrices
    marketingData: MarketingData
    producerSlug: string
    inputs: InputGroups
    product: ELProduct
    pricelistUrls?: FuelPricelistUrls
    stage: StageName
  }
}

export type NewResponse<T = undefined> = {
  success: boolean
  data?: T
  message?: string
  error?: string
}

export type Response = {
  success: boolean
  data?: any
  message?: string
}

// ENUM

export enum StageName {
  CLOSED_LOST = 'Closed Lost',
  CLOSED_WON = 'Closed Won',
  IN_PROGRESS = 'In Progress',
  NEEDS_ANALYSIS = 'Needs Analysis',
  NEGOTIATION = 'Negotiation',
  NEW = 'New',
  NO_EAN = 'No EAN',
  PARKED = 'Parked',
  PROPOSAL = 'Proposal',
  QUALIFICATION = 'Qualification',
  TO_BE_CONFIRMED = 'To Be Confirmed'
}
