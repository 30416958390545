import { createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from 'store'
import { DEFAULT_PRODUCTS } from 'constants/customerFlow'
import { Product } from 'types/product-data'
import { mapSimulatePriceRequest } from 'features/simulation/utils'
import { createCustomer, fetchPriceSimulation } from 'api/customerFlow'
import type { CreateCustomerData } from 'types/customer'
import Bugsnag from '@bugsnag/js'
import type { SimulatedPropositions } from 'store/customer/types'
import { log } from 'utils/logging'
import { Flow } from 'types/logging'

export const runSimulation = createAsyncThunk<SimulatedPropositions, { products?: Product[] }>(
  'customer/runSimulation',
  async ({ products }, thunkAPI) => {
    try {
      // Fetch current propositions from the store
      const { propositions } = (thunkAPI.getState() as RootState).customer
      const tempPropositions = { ...propositions?.simulatedPropositions }

      const previouslySimulatedProducts =
        tempPropositions && Object.keys(tempPropositions).length ? (Object.keys(tempPropositions) as Product[]) : undefined
      const productsToSimulate = products || previouslySimulatedProducts || DEFAULT_PRODUCTS

      // Fetch propositions for all the products
      await Promise.all(
        productsToSimulate.map(async (product) => {
          // Map the request data
          const simulatePriceRequest = mapSimulatePriceRequest(product)

          Bugsnag.addMetadata('simulationRequestData', simulatePriceRequest)

          // Fetch the price(s)
          const [price, priceWithoutPromo] = await Promise.all([
            fetchPriceSimulation(simulatePriceRequest),
            simulatePriceRequest.discountCode
              ? fetchPriceSimulation({
                  ...simulatePriceRequest,
                  discountCode: undefined
                })
              : undefined
          ])

          Bugsnag.addMetadata('priceSimulationResponse', {
            [product]: {
              success: price?.success,
              price: price?.success ? price?.data?.price : price?.message || price?.error,
              priceWithoutPromo: priceWithoutPromo?.success
                ? priceWithoutPromo?.data?.price
                : priceWithoutPromo?.message || priceWithoutPromo?.error
            }
          })

          // TODO: not sure if this is the way to go, if a simulation returns no price it probably has failed the request.
          //  Thus we should not be able to proceed with the simulation/registration
          if (!price.success) return

          // Set/replace the proposition for the current product type
          tempPropositions[product] = {
            price: price?.data?.price,
            priceWithoutPromo: priceWithoutPromo?.data?.price?.total[simulatePriceRequest.isCompany ? 'totalWithoutVAT' : 'total']
          }
        })
      )

      if (!Object.keys(tempPropositions).length) {
        log({ error: 'Failed to fetch propositions', identifier: `[${Flow.SIMULATION}:runSimulation]` })
        return thunkAPI.rejectWithValue('[runSimulation]: Failed to fetch the propositions')
      }

      return tempPropositions
    } catch (error) {
      log({ error, identifier: `[${Flow.SIMULATION}:runSimulation]` })
      return thunkAPI.rejectWithValue('[runSimulation]: Internal error')
    }
  }
)

export const registerClient = createAsyncThunk(
  'customer/registerClient',
  async ({ customerData }: { customerData: CreateCustomerData }, thunkAPI) => {
    const { success, message } = await createCustomer(customerData)

    if (!success) {
      Bugsnag.addMetadata('createCustomerErrorMsg', { message })
      return thunkAPI.rejectWithValue('Failed to register the customer')
    }

    return success
  }
)
