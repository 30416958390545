import { SimulatePriceRequest, SimulatePriceResponse } from 'features/simulation/types'
import { TownResponse } from 'types/towns'
import { CheckPromoReferralCodeResponse, GetReferralCodeResponse } from 'types/promo-referral-codes'
import request, { getAxiosRequestConfig, newRequest } from 'utils/request'
import { AllProducts } from 'types/product-data'
import { GetDataByOpportunityIdResponse, NewResponse, Response } from 'types/request'
import { Address, CreateCustomerData, WarmProspectData, CreateProspectData } from 'types/customer'
import { Language } from 'types/language'
import { EanLookupResponseBody, EnergyType, GetEanDataResponseBody } from 'types/contract'
import { stringify } from 'qs'

/**
 * Check if the given code is a valid promo or referral through an API call to SF
 *
 * @param code
 * @return Promise<CheckPromoReferralCodeResponse>
 */
export const checkPromoReferralCode = async (code: string): Promise<CheckPromoReferralCodeResponse> => {
  const response = await request(getAxiosRequestConfig('GET', `website/promo-referral-code?code=${code}`))

  return response.success ? response.data : { valid: false }
}

/**
 * Creates a customer in Salesforce
 *
 * @param {CreateCustomerData} data
 * @return {Promise<{ success: boolean }>}
 */
export const createCustomer = async (data: CreateCustomerData): Promise<{ success: boolean; message: string }> => {
  const { success, message } = await request(getAxiosRequestConfig('POST', 'customers', data))

  return { success, message }
}

/**
 * Creates a warm prospect
 *
 * @param {WarmProspectData} data
 * @returns {Promise<{ success: boolean }>}
 */
export const upsertWarmProspect = async (data: WarmProspectData): Promise<{ success: boolean }> => {
  const { success } = await newRequest(getAxiosRequestConfig('POST', '/prospects/warm', data))

  return { success }
}

/**
 * Creates a prospect
 *
 * @param {CreateProspectData} data
 * @return {Promise<CreateProspectReturnType>}
 */
export const createProspect = async (data: CreateProspectData): Promise<Response> => {
  // Create the customer prospect through the API
  return newRequest(getAxiosRequestConfig('POST', '/prospects', data))
}

/**
 * Fetches the EAN numbers based on the given language & address
 *
 * @param {Language} language
 * @param {Address} address
 * @returns {Promise<EanLookupResponseBody>}
 */
export const eanLookup = async (language: Language, address: Address): Promise<EanLookupResponseBody> => {
  const { success, data } = await newRequest<EanLookupResponseBody>(
    getAxiosRequestConfig('POST', '/market-message/preswitch/ean-lookup', { address, language })
  )

  return success && data ? data : { electricity: null, gas: null }
}

/**
 * Fetches the customer data based on the opportunity id
 *
 * @param opportunityId
 * @returns Promise<GetDataByOpportunityIdResponse>
 */
export const fetchDataByOpportunityId = async (opportunityId: string): Promise<GetDataByOpportunityIdResponse> => {
  const { success, data } = await newRequest<GetDataByOpportunityIdResponse>(getAxiosRequestConfig('GET', `prospects/${opportunityId}`))

  return success ? data : null
}

/**
 * Fetches the pricelists for all products
 *
 * @returns Promise<AllProducts|null>
 */
export const fetchProducts = async (): Promise<AllProducts | null> => {
  const { success, data } = await newRequest<{ products: AllProducts }>(getAxiosRequestConfig('GET', 'products'))

  return success ? data.products : null
}

/**
 * Fetches the price simulation from the API
 *
 * @param {SimulatePriceRequest} simulationData
 * @return Promise<NewResponse<SimulatePriceResponse>>
 */
export const fetchPriceSimulation = async (simulationData: SimulatePriceRequest): Promise<NewResponse<SimulatePriceResponse>> => {
  return await request(getAxiosRequestConfig('POST', 'website/price-simulation', simulationData))
}

/**
 * Fetches the switch date for the given EAN
 *
 * @param {EnergyType} energyType
 * @param {string} ean
 * @param {string} contractStartDate
 * @returns {Promise<string|null>}
 */
export const getEanData = async (
  energyType: EnergyType,
  ean: string,
  contractStartDate?: string
): Promise<GetEanDataResponseBody | null> => {
  const query = stringify({ contractStartDate, ean, energyType }, { addQueryPrefix: true, skipNulls: true })

  const { success, data } = await newRequest<GetEanDataResponseBody>(
    getAxiosRequestConfig('GET', `/market-message/preswitch/ean-data${query}`)
  )

  return success ? data : null
}

/**
 * Gets the referral code for a customer by a given email address
 *
 * @param {string} email
 * @param {string} sfUserAlias
 * @return Promise<string>
 */
export const getReferralCodeByEmail = async (email: string, sfUserAlias?: string): Promise<string | null> => {
  const searchQuery = stringify({ email, sfUserAlias }, { addQueryPrefix: true, skipNulls: true })
  const url = `customers/referral-code${searchQuery}`
  const { success, data }: NewResponse<GetReferralCodeResponse> = await newRequest(getAxiosRequestConfig('GET', url))

  return success && data?.code ? data.code : null
}

/**
 * Fetch the town from the API based on the given postal code
 *
 * @param postalCode
 * @return Promise<TownResponse>
 */
export const getTown = async (postalCode: number): Promise<TownResponse> => {
  const response = await request(getAxiosRequestConfig('GET', `towns/${postalCode}`))

  return response.success ? response.data : null
}

/**
 * Checks if a given email is already registered as a customer in Salesforce
 *
 * @param email
 * @return Promise<boolean>
 */
export const isCustomer = async (email: string): Promise<boolean> => {
  const response = await request(getAxiosRequestConfig('GET', `customers/exists?email=${email.toLowerCase()}`))

  return response.success
}

/**
 * Reconfirms the contract through the API
 *
 * @param {string} serviceContractId The Salesforce service contract id that needs to be reconfirmed
 * @return {Promise<boolean>} Wether or not the reconfirmation was succesfull
 */
export const reconfirm = async (serviceContractId: string): Promise<boolean> => {
  const { success } = await newRequest(getAxiosRequestConfig('PATCH', `contracts/${serviceContractId}/reconfirm`))

  return success
}
