import { EnergyType, FilterSortProducers } from 'types/producer'
import { CookieKeys } from 'types/cookies'
import { CapacityState, Producer, State } from 'types/producer'
import { readCookie } from 'utils/cookies'
import { getTotalElectricityVolumeForMeterType } from 'utils/helpers'
import { PopulatedLocation } from 'components/producers/new-producer-map/types'
import { InputGroups } from 'store/customer/types'
import { InputGroupKeys } from 'store/customer/enums'
import { AVERAGE_CAPACITY_USAGE } from 'constants/usages'

/**
 * Fetches the capacity state for a given producer
 *
 * @param {PopulatedLocation} producer
 * @param {InputGroups[InputGroupKeys.SIMULATION]} simulationInputs
 * @param {boolean} didSimulation
 * @returns {CapacityState}
 */
export const getCapacityState = (
  producer: PopulatedLocation,
  simulationInputs: InputGroups[InputGroupKeys.SIMULATION],
  didSimulation: boolean
): CapacityState => {
  const usedEnergy = producer.currentCapacity > 100 ? 100 : Math.round(producer.currentCapacity)

  // If producer is marked as sold out in CMS
  if (producer.state === State.SOLD_OUT || usedEnergy >= 100) {
    return { almostSoldOut: true, soldOut: true, usedEnergy: 100 }
  }

  // If producer is marked as reserved in CMS
  // (unless user has unlocked producer using ?unlock=id)
  if (isReserved(producer)) {
    return { almostSoldOut: false, soldOut: false, usedEnergy: 100 }
  }

  // If user did no simulation, assume average usage of 3500 kWh (also max usage for express registration)
  let volume = AVERAGE_CAPACITY_USAGE
  if (didSimulation) {
    // If user did simulation, take sum of usages based on meter type
    volume = getTotalElectricityVolumeForMeterType(simulationInputs.meterType, simulationInputs.usage)
  }

  const used = Math.round(((producer.energyUsed + volume) / producer.maxCapacity) * 100)

  // Use minimum percentage from CMS for display
  // We don't want to show 0%
  let visiblePercentage = producer.minOccupation
  if (used > producer.minOccupation) {
    // If the actual used capacity is higher than the CMS value, use the calculated one
    visiblePercentage = used
  }
  // User is allowed to sign up if less than 100% used
  // Make sure the visible percentage is not above 100
  return {
    almostSoldOut: used >= 80,
    soldOut: used >= 100,
    usedEnergy: Number(Math.min(visiblePercentage, 100).toFixed(0))
  }
}

/**
 * Returns the i18n key for given energy type
 *
 * @param energyType
 * @returns string
 */
export const getEnergyNameI18Key = (energyType: string): string => {
  switch (energyType) {
    case EnergyType.ZON:
      return 'solar'

    case EnergyType.WATER:
      return 'water'

    case EnergyType.WIND:
      return 'wind'

    case EnergyType.BIO:
      return 'bio'

    default:
      return ''
  }
}

/**
 * Checks if a given producer is reserved
 *
 * @param {PopulatedLocation} producer
 * @returns boolean
 */
export const isReserved = (producer: PopulatedLocation): boolean => {
  if (!producer.state || producer.state !== State.RESERVED) {
    return false
  }

  const unlockedProducerId = readCookie(CookieKeys.UNLOCK)

  if (!unlockedProducerId) {
    return true
  }

  return producer.id !== String(unlockedProducerId)
}

export const isSoldOutWithCapacity = (producer: Producer, capacity?: number): boolean => {
  // assume 3500 kWh
  const volume = capacity || AVERAGE_CAPACITY_USAGE
  const used = Math.round(((producer.energyUsed + volume) / producer.maxCapacity) * 100)

  return used >= 100
}

/**
 * Filters and sorts producers based on being enabled, popularity, capacity and order
 *
 * @param {Producer[]} producers
 * @param {Language} language
 * @param {boolean} hideSoldOut
 * @param {number} capacity
 * @returns {Producer[]}
 */
export const filterAndSortProducers = ({ producers, language, hideSoldOut, capacity }: FilterSortProducers): Producer[] => {
  const popularProducers = producers.filter((p) => p.isPopular[language] && !isSoldOutWithCapacity(p, capacity))

  const soldOutProducers = hideSoldOut
    ? []
    : producers.filter((p) => isSoldOutWithCapacity(p, capacity)).sort((p1, p2) => p1.currentCapacity - p2.currentCapacity)

  const otherProducers = producers
    .filter((p) => !isSoldOutWithCapacity(p, capacity) && !p.isPopular[language])
    .sort((p1: Producer, p2: Producer) => {
      return p1.order < p2.order ? -1 : p1.order > p2.order ? 1 : 0
    })

  return [...popularProducers, ...otherProducers, ...soldOutProducers]
}
