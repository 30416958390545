import { AddressFields } from 'components/form-fields/address-section/types'
import { REGISTRATION_FLOW } from 'features/registration/constants'
import { InvoiceMedium, PaymentMethod, RegistrationSteps, Situation } from 'features/registration/types'
import { getStartDateRange } from 'features/registration/utils'
import { Language } from 'types/language'
import { EanCaptureType } from 'types/marketing-params'
import {
  BillingFieldKeys,
  CustomerFlows,
  InputGroupKeys,
  MeterDetailsFieldKeys,
  OverviewFieldKeys,
  PersonalDataFieldKeys,
  SimulationFieldKeys
} from './enums'
import { CustomerStore } from './types'
import { INITIAL_FOW } from 'features/simulation/constants'
import { CounterTypes, MeterType, SimulationSteps } from 'features/simulation/types'
import { EnergyUsageGrade } from 'types/usages'
import { ESTIMATED_USAGES } from 'constants/usages'
import { SimulationType } from 'store/marketing/types'

export const initialCustomerState: CustomerStore = {
  flows: {
    [CustomerFlows.REGISTRATION]: {
      currentStep: RegistrationSteps.PERSONAL_DATA,
      error: false,
      isLoading: false,
      steps: REGISTRATION_FLOW
    },
    [CustomerFlows.SIMULATION]: {
      contactMe: undefined,
      currentStep: SimulationSteps.POSTAL_CODE,
      didSimulation: false,
      error: false,
      isLoading: false,
      steps: INITIAL_FOW,
      visible: false
    }
  },
  inputs: {
    [InputGroupKeys.SIMULATION]: {
      [SimulationFieldKeys.USAGE_GRADE]: EnergyUsageGrade.LOW,
      [SimulationFieldKeys.REGION]: null,
      [SimulationFieldKeys.METER_TYPE]: MeterType.SINGLE_RATE,
      [SimulationFieldKeys.HAS_EXCLUSIVE_NIGHT_METER]: false,
      [SimulationFieldKeys.USAGE]: {
        electricity: {
          consumption: {
            single: ESTIMATED_USAGES[EnergyUsageGrade.LOW].Residential.electricity.consumption
          }
        }
      },
      [SimulationFieldKeys.PRICE_WITHOUT_PROMO]: null,
      [SimulationFieldKeys.FORCED_AMOUNT]: null,
      [SimulationFieldKeys.REFERRAL_CODE]: null,
      [SimulationFieldKeys.PROMO_CODE]: null,
      [SimulationFieldKeys.SOLAR_PANELS]: null,
      [SimulationFieldKeys.SOLAR_KVA]: null,
      [SimulationFieldKeys.CHOSEN_SIMULATION_TYPE]: SimulationType.PRICING_SIMULATION
    },
    [InputGroupKeys.PERSONAL_DATA]: {
      [PersonalDataFieldKeys.DELIVERY_ADDRESS]: {
        [AddressFields.STREET]: '',
        [AddressFields.NUMBER]: '',
        [AddressFields.BOX]: '',
        [AddressFields.POSTAL_CODE]: '',
        [AddressFields.TOWN_NAME]: undefined,
        [AddressFields.TOWN_CODE]: 0
      },
      [PersonalDataFieldKeys.NEEDS_GAS]: false,
      [PersonalDataFieldKeys.IS_COMPANY]: false,
      [PersonalDataFieldKeys.FIRSTNAME]: '',
      [PersonalDataFieldKeys.LASTNAME]: '',
      [PersonalDataFieldKeys.EMAIL]: '',
      [PersonalDataFieldKeys.REPEAT_EMAIL]: '',
      [PersonalDataFieldKeys.MOBILE_PHONE]: '',
      [PersonalDataFieldKeys.MOBILE_PHONE_COUNTRY_CODE]: '',
      [PersonalDataFieldKeys.DATE_OF_BIRTH]: {
        day: '',
        month: '',
        year: ''
      },
      [PersonalDataFieldKeys.LANGUAGE]: Language.DUTCH,
      [PersonalDataFieldKeys.CREDIT_CHECK]: {
        executed: false
      }
    },
    [InputGroupKeys.METER_DETAILS]: {
      [MeterDetailsFieldKeys.SITUATION]: Situation.SWITCH,
      [MeterDetailsFieldKeys.UPLOAD]: null,
      [MeterDetailsFieldKeys.METER_READING_MONTH]: null,
      [MeterDetailsFieldKeys.CONTRACT_START_DATE]: getStartDateRange(Situation.SWITCH).initial,
      [MeterDetailsFieldKeys.HAS_SOLAR_PANELS]: false,
      [MeterDetailsFieldKeys.COUNTER_TYPE]: CounterTypes.DIGITAL,
      [MeterDetailsFieldKeys.KNOWS_METER_DETAILS]: true,
      [MeterDetailsFieldKeys.ELECTRICITY]: {
        day: null,
        ean: null,
        eanExclNight: null,
        exclNight: null,
        meterNumber: null,
        night: null,
        total: null
      },
      [MeterDetailsFieldKeys.GAS]: {
        ean: null,
        meterNumber: null,
        total: null
      },
      [MeterDetailsFieldKeys.EAN_CAPTURE_TYPE]: EanCaptureType.NO_LOOKUP_DONE
    },
    [InputGroupKeys.BILLING]: {
      [BillingFieldKeys.SAME_CORRESPONDENCE_ADDRESS]: true,
      [BillingFieldKeys.CORRESPONDENCE_ADDRESS]: {
        [AddressFields.STREET]: '',
        [AddressFields.NUMBER]: '',
        [AddressFields.BOX]: '',
        [AddressFields.POSTAL_CODE]: '',
        [AddressFields.TOWN_NAME]: '',
        [AddressFields.TOWN_CODE]: 0
      },
      [BillingFieldKeys.PAYMENT_METHOD]: PaymentMethod.DIRECT_DEBIT,
      [BillingFieldKeys.INVOICE_MEDIUM]: InvoiceMedium.DIGITAL,
      [BillingFieldKeys.IBAN]: ''
    },
    [InputGroupKeys.OVERVIEW]: {
      [OverviewFieldKeys.SIGNATURE]: '',
      [OverviewFieldKeys.CHOSEN_SIGN_CHANNEL]: undefined
    }
  },
  isFetchingProducers: false,
  personalizedProducers: {
    [Language.DUTCH]: {},
    [Language.FRENCH]: {}
  },
  propositions: {
    simulatedPropositions: null
  }
}
